<template>
  <div class="container">
    <el-dialog :visible.sync="dialogVisible" width="100%">
      <span slot="title" style="color: #fff; font-weight: 500"
        >Thông tin gia sư</span
      >
      <div style="max-width: 150px; margin: 0 auto">
        <img
          width="100%"
          src="@/assets/avartar.png"
          style="border-radius: 20px 0 20px 0"
          alt=""
        />
      </div>
      <div style="color: #fff; text-align: center">
        <p style="font-size: 22px; margin: 0">{{ studentInfo.StudentName }}</p>
        <p style="font-size: 16px; margin: 0">Gia sư</p>
      </div>
      <div style="margin-top: 24px; color: #fff">
        <div style="display: flex">
          <i
            class="fa-solid fa-circle-info"
            style="font-size: 20px; margin-top: 2px"
          ></i>
          <p style="margin: 0; margin-left: 8px; font-size: 18px">
            THÔNG TIN LIÊN HỆ
          </p>
        </div>
        <div style="display: flex; margin-left: 16px; margin-top: 8px">
          <i
            class="fa-solid fa-id-badge"
            style="font-size: 14px; margin-top: 2px"
          ></i>

          <p style="margin: 0; margin-left: 8px; font-size: 13px">
            {{ studentInfo.StudentID }}
          </p>
        </div>
        <div style="display: flex; margin-left: 16px; margin-top: 8px">
          <i
            class="fa-solid fa-envelope"
            style="font-size: 14px; margin-top: 2px"
          ></i>

          <p style="margin: 0; margin-left: 8px; font-size: 13px">
            {{ studentInfo.Email }}
          </p>
        </div>
        <div style="display: flex; margin-left: 16px; margin-top: 8px">
          <i
            class="fa-solid fa-phone"
            style="font-size: 14px; margin-top: 2px"
          ></i>
          <p style="margin: 0; margin-left: 8px; font-size: 13px">
            {{ studentInfo.Phone }}
          </p>
        </div>
        <div style="display: flex; margin-left: 16px; margin-top: 8px">
          <i
            class="fa-solid fa-location-dot"
            style="font-size: 14px; margin-top: 2px"
          ></i>

          <p style="margin: 0; margin-left: 8px; font-size: 13px">
            {{ studentInfo.Ward }}, {{ studentInfo.District }}
          </p>
        </div>
      </div>

      <div style="margin-top: 24px; color: #fff">
        <div style="display: flex">
          <i
            class="fa-brands fa-envira"
            style="font-size: 20px; margin-top: 2px"
          ></i>
          <p style="margin: 0; margin-left: 8px; font-size: 18px">
            MỤC TIÊU NGHÊ NGHIỆP
          </p>
        </div>
        <div v-for="(per, index) in studentInfo.ListPerpose" :key="index">
          <p style="margin-left: 16px">- {{ per }}</p>
        </div>
      </div>

      <div style="margin-top: 24px; color: #fff">
        <div style="display: flex">
          <i
            class="fa-solid fa-dollar-sign"
            style="font-size: 20px; margin-top: 2px"
          ></i>
          <p style="margin: 0; margin-left: 8px; font-size: 18px">
            MONG MUỐN HỌC PHÍ
          </p>
        </div>
        <div>
          <p style="margin-left: 16px">
            - Khoảng:
            {{ new Intl.NumberFormat().format(studentInfo.ValuePrice) }} VNĐ
          </p>
        </div>
        <!-- <div v-for="per,index in studentInfo.ListPerpose" :key="index">
          <p style="margin-left: 16px"> - {{per}}</p>
        </div> -->
      </div>

      <div style="margin-top: 24px; color: #fff">
        <div style="display: flex">
          <i
            class="fa-solid fa-leaf"
            style="font-size: 20px; margin-top: 2px"
          ></i>
          <p style="margin: 0; margin-left: 8px; font-size: 18px">KỸ NĂNG</p>
        </div>
        <div v-for="(sub, index) in studentInfo.ListSub" :key="index">
          <div style="margin-top: 8px">
            <div style="display: flex; justify-content: space-between">
              <span style="margin: 0; margin-bottom: 4px">{{ sub.sub }}</span>
              <span style="margin: 0; margin-bottom: 4px"
                >{{ sub.value }}0%</span
              >
            </div>

            <div class="container-line">
              <div class="line" :style="'width:' + sub.value + '0%'"></div>
            </div>
          </div>
        </div>
      </div>
      <div style="margin-top: 24px; color: #fff">
        <div style="display: flex">
          <i
            class="fa-brands fa-envira"
            style="font-size: 20px; margin-top: 2px"
          ></i>
          <p style="margin: 0; margin-left: 8px; font-size: 18px">
            KHẢ NĂNG BẢN THÂN
          </p>
        </div>
        <div v-for="(hel, index) in studentInfo.ListHelp" :key="index">
          <p style="margin-left: 16px">- {{ hel }}</p>
        </div>
      </div>

      <div style="margin-top: 24px; color: #fff">
        <div style="display: flex">
          <i
            class="fa-brands fa-envira"
            style="font-size: 20px; margin-top: 2px"
          ></i>
          <p style="margin: 0; margin-left: 8px; font-size: 18px">
            TÓM TẮT BẢN THÂN
          </p>
        </div>
        <div v-for="(sum, index) in studentInfo.ListSum" :key="index">
          <p style="margin-left: 16px">{{ sum }}</p>
        </div>
      </div>
      <div style="margin-top: 24px; color: #fff">
        <div style="display: flex">
          <i
            class="fa-solid fa-briefcase"
            style="font-size: 20px; margin-top: 2px"
          ></i>
          <p style="margin: 0; margin-left: 8px; font-size: 18px">
            THÔNG TIN THÊM
          </p>
        </div>
        <div>
          <p style="margin-top: 8px">
            {{ studentInfo.InfoMore }}
          </p>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <!-- <el-button @click="dialogVisible = false">Cancel</el-button>
        <el-button type="primary" @click="dialogVisible = false"
          >Confirm</el-button
        > -->
      </span>
    </el-dialog>
    <div class="content" :key="updateUI">
      <p>Danh sách gia sư phù hợp</p>
      <div v-for="(st, index) in studentLst" :key="index">
        <student-cell
          :studentInfo="st"
          @btSeeInfo="btSeeInfo(st)"
          @btSelected="btSelected(st)"
        />
      </div>

      <!-- <student-cell @btSeeInfo="btSeeInfo" />
      <student-cell @btSeeInfo="btSeeInfo" /> -->
      <div style="text-align: center">
        <el-button
          style="background-color: #009394; border: none"
          type="success"
          size="small"
          :loading="loadBtConfirm"
          @click="createCourse"
          >Xác nhận chọn gia sư</el-button
        >
      </div>
    </div>
  </div>
</template>

<script>
import studentCell from "./components/student-cell.vue";
import { GetStudentLstByParentID } from "../../api/studentApi.js";
import { CreateCourse } from "../../api/parentApi.js";
export default {
  data() {
    return {
      dialogVisible: false,
      studentLst: [],
      studentInfo: {},
      updateUI: 0,
      studentID: null,
      loadBtConfirm: false,
    };
  },
  components: {
    studentCell,
  },
  methods: {
    btSeeInfo(st) {
      this.dialogVisible = true;
      this.studentInfo = st;
    },
    btSelected(st) {
      var lst = this.studentLst.filter((info) => info.Select == true);
      if (lst.length > 0) {
        lst[0].Select = false;
      }
      st.Select = true;
      this.studentID = st.StudentID;
      this.updateUI++;
    },
    getStudentApply() {
      GetStudentLstByParentID({
        ReqParentID: this.$route.params.req,
      }).then((res) => {
        this.studentLst = res.StudentLst.map((st, index) => {
          return {
            ...st,
            Key: index + 1,
            ListSub: JSON.parse(st.Subjects),
            ListPerpose: st.Perposes.split("; "),
            ListHelp: st.Helps.split("; "),
            ListSum: st.Summary.split("; "),
            Select: false,
          };
        });
      });
    },
    createCourse() {
      if (this.studentID) {
        this.loadBtConfirm = true;
        CreateCourse({
          ReqParentID: this.$route.params.req,
          StudentID: this.studentID,
        }).then((res) => {
          this.loadBtConfirm = false;
          if (res.RespCode == 0) {
            this.$notify({
              title: "Thành công",
              message: "Gia sư sẽ sớm liên lạc cho bạn",
              type: "success",
            });
          }
        });
      } else {
        this.$notify({
          title: "Nhắc nhở",
          message: "Bạn chưa chọn gia sư",
          type: "warning",
        });
      }
    },
  },
  created() {
    this.getStudentApply();
  },
};
</script>
<style lang="scss" scoped>
.container {
  min-height: 500px;
  .content {
    padding-top: 1px;
    padding-bottom: 1px;
    p {
      text-align: center;
      margin: 10px;
      color: #0272dd;
      font-weight: bold;
    }
  }
  .container-line {
    height: 12px;
    background: #fff;
    padding-top: 2px;
    .line {
      background: #01887a;
      height: 10px;
      margin: auto 2px;
      width: 60%;
    }
  }
}
</style>
<style>
.el-dialog {
  max-width: 600px;
  background: #01887a;
  margin-top: 50px !important;
}
.el-dialog__body {
  padding: 12px;
}
</style>

<template>
  <div class="container-cell">
    <div
      style="
        margin: 0 8px;
        background: #fff;
        border-radius: 12px;
        padding: 10px 0;
        display: grid;
        grid-template-columns: 30px auto 80px;
      "
      :class="{'selected': studentInfo.Select}"
    >
      <div
        style="
          height: 24px;
          width: 24px;
          border-radius: 50%;
          background: #01887a;
          margin-left: 8px;
          
        "
      >
        <p style="margin: 2px 8px; color: #fff">{{ studentInfo.Key }}</p>
      </div>
      <div>
        <p>
          Gia sư: {{ studentInfo.StudentName }} - {{ studentInfo.StudentID }}
        </p>
        <p style="font-size: 13px">
          <i class="fa-solid fa-location-dot"></i> {{ studentInfo.Ward }},
          {{ studentInfo.District }}
        </p>
      </div>
      <div style="text-align: right; margin-right: 8px; width: 70px">
        <button style="background: #0272dd; color: #fff" @click="btSeeInfo">
          Xem
        </button>
        <button
          style="background: none; color: #1d974a; border: 1px solid #1d974a"
          :class="{'selected': studentInfo.Select}"
           @click="btSelected"
        >
          Chọn
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props:{
    studentInfo:Object
  },
  methods: {
    btSeeInfo() {
      this.$emit("btSeeInfo");
    },
    btSelected(){
      this.$emit("btSelected");
    }
  },
};
</script>
<style lang="scss" scoped>
.container-cell {
  margin: 8px 0; 
  // border: 1px solid red;
  p {
    margin: 0 8px;
    color: #01887a;
    font-weight: 500;
    font-size: 15px;
  }
  button {
    border: none;
    border-radius: 15px;
    height: 24px;
    padding: 0 12px;
    font-size: 12px;
    width: 60px;
    cursor: pointer;
  }
}
.selected{
  color: #e9446a !important;
  border: 1px solid #e9446a !important
}
</style>
